<template>
    <div>
        <Map />
    </div>
</template>

<script>
import Map from '../MapComponent'

export default {
    name: 'Visualiser',
    components: {
        Map,
    },
}
</script>
